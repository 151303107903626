export const FirebaseConfig = {
	"projectId": "xtrucks-f5303",
	"appId": "1:162242494047:web:4895440a4cf1eeddaf25ea",
	"databaseURL": "https://xtrucks-f5303-default-rtdb.firebaseio.com",
	"storageBucket": "xtrucks-f5303.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBqy_HeB-GmyI1oAUNlAvm0pXqwmieL6Yo",
	"authDomain": "xtrucks-f5303.firebaseapp.com",
	"messagingSenderId": "162242494047",
	"measurementId": "G-1EQ7WLHT30"
};